<template lang="html">
    <div class="container white">
        <top-navigation-bar
            title="One Variance"
            right-nav="reset"
            @click="doReset"
        />
        <h4 class="mb-3 text-center divider">
            What do you want to test?
        </h4>

        <div class="m-view d-flex py-3">
            <h2>H<sub>A</sub> : </h2>
            <img
                class="formulaImage-char-sm"
                src="@/assets/HTformulaImgs/variance/sigmaSquared.png"
            >
            <select
                v-model="selected.value"
                class="form-select-lg mx-2"
            >
                <option
                    v-for="(option, key) in options"
                    :key="key"
                >
                    {{ option.value }}
                </option>
            </select>
            <input
                v-model="userPopVar"
                placeholder="?"
                class="input-single w-25 py-1"
                @keyup.enter="onSubmit()"
            >
        </div>
        <input-single
            v-model="n"
            :label="{text: 'Sample Size', mathChar: 'n'}"
            style-class="input-single"
            @enter-pressed="onSubmit()"
        />
        <input-single
            v-model="s"
            :label="{text: 'Std Dev', mathChar: 's'}"
            style-class="input-single"
            @enter-pressed="onSubmit()"
        />
        <div class="sticky-bottom m-3 mt-5">
            <button
                class="action-btn btn-block"
                block
                @click="onSubmit()"
            >
                Calculate
            </button>
        </div>

        <modal
            ref="modalOutput"
            title="One Variance - Result"
            modal-type="hypothesis"
        >
            <template #formula>
                <div class="m-view d-flex">
                    <h2 class="mr-2">
                        H<sub>0</sub> :
                    </h2>
                    <img
                        class="formulaImage-char-sm mr-2"
                        src="@/assets/HTformulaImgs/variance/sigmaSquared.png"
                    >
                    <h2 class="mx-1">
                        = {{ userPopVar }}
                    </h2>
                </div>
                <div class="m-view d-flex ml-4">
                    <h2>H<sub>A</sub> :</h2>
                    <img
                        class="formulaImage-char-sm"
                        src="@/assets/HTformulaImgs/variance/sigmaSquared.png"
                    >
                    <select
                        v-model="selected.value"
                        class="form-select-lg mx-2 mb-2"
                        @change="onSubmit()"
                    >
                        <option
                            v-for="(option, key) in options"
                            :key="key"
                        >
                            {{ option.value }}
                        </option>
                    </select>
                    <input
                        v-model="userPopVar"
                        placeholder="?"
                        class="input-single h3 w-25 py-1"
                        @keyup.enter="onSubmit()"
                    >
                </div>
            </template>

            <template #image>
                <div class="m-view d-flex justify-content-start">
                    <img
                        class="formulaImage-med"
                        src="@/assets/HTformulaImgs/variance/ChiSquare_formula.png"
                    >
                    <h3 class="formulaImage-text">
                        = {{ chiSq2 }}
                    </h3>
                </div>
                <h3 class="pt-4 ml-2">
                    df = n - 1 = {{ df }}
                </h3>
            </template>
            <template #graph>
                <h4 class="my-2 text-center">
                    p-Value = {{ notEqualPdisplay }}P( 𝛘 ² {{ symbolPdisplay }} {{ chiSq2 }}) = {{ pVal4 }}
                </h4>
            </template>
            <template #conclusion>
                <h3 class="mb-3 text-center">
                    Conclusion:
                </h3>
                <h4 class="text-center pt-2">
                    There is a {{ probDisplay }}% chance of concluding
                </h4>
                <div class="image-row justify-content-center">
                    <img
                        class="formulaImage-char-sm"
                        src="@/assets/HTformulaImgs/variance/sigmaSquared.png"
                    >
                    <h4>{{ selected.value }} {{ userPopVar }} and being wrong</h4>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import InputSingle from '@/components/input-single.vue'
import Modal from '@/components/modal.vue'
import hypothesisData from '@/js/hypothesis/hypothesis-data.js'
import localStore from '@/store/localStore.js'
import { clearData } from '@/js/lib/input-check.js'
import _ from 'lodash'

export default {
    name: 'OneVarianceInput',
    components: {
        TopNavigationBar,
        InputSingle,
        Modal
    },
    data () {
        return {
            allData: {},
            computeType: 'hypothesis',
            // input
            userPopVar: '', // Assumed value for p in H𝗈
            n: '', // Sample Size
            s: '', // Sample Mean of paired differences
            // output
            df: '',
            chiSq2: '',
            probLeft: '',
            probRight: '',
            pVal4: '',
            // picker
            options: [
                { value: '<' },
                { value: '>' },
                { value: '≠' },
            ],
            selected: { value: '>' }
        }
    },
    computed: {
        notEqualPdisplay () {
            const output = this.selected.value === '≠' ? '2' : ''
            return output
        },
        symbolPdisplay () {
            var { selected, probLeft } = this
            if (selected.value !== '≠') {
                return selected.value
            } else {
                const symbol = probLeft < 0.5 ? '<' : '>'
                return symbol
            }
        },
        probDisplay () {
            var pVal4 = _.get(this, 'pVal4', '')
            const output = Number((pVal4 * 100).toFixed(2))
            return output
        },
    },
    beforeMount () {
        var store = localStore.getStore('hypothesis-data')
        if (store) {
            _.assign(this, { allData: store })
            var formulaStore = _.get(store, 'OneV', '')
            if (formulaStore) {
                _.assign(this, formulaStore.input)
            }
        }
    },
    methods: {
        doReset () {
            _.assign(this, clearData(this, ['userPopVar', 's', 'n']))
        },
        showModal () {
            this.$refs.modalOutput.show()
        },
        onSubmit () {
            var { allData } = this
            const output = hypothesisData.calculateOneVariance(this)
            if (!output) {
                console.log('Calculation Error')
            } else {
                _.assign(this, output.data)

                _.set(allData, 'OneV', { input: output.input, output: output.data })
                localStore.setStoreItem('hypothesis-data', allData)
                this.showModal()
            }
        },
    },
}
</script>
